<template>
  <div v-if="deferredPrompt" class="banner">
    <div class="message">
      Get our free app. It won't take up space on your phone and also works
      offline!
    </div>
    <div class="button-group">
      <button class="btn dismiss-btn" @click="dismiss">Dismiss</button>
      <button class="btn install-btn" @click="install">Install</button>
    </div>
  </div>
</template>
<script>
import Cookies from "js-cookie";
export default {
  name: "Banner",
  data() {
    return {
      deferredPrompt: null,
    };
  },
  created() {
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      // Stash the event so it can be triggered later.
      if (Cookies.get("add-to-home-screen") === undefined) {
        this.deferredPrompt = e;
      }
    });
    window.addEventListener("appinstalled", () => {
      this.deferredPrompt = null;
    });
  },
  methods: {
    async dismiss() {
      Cookies.set("add-to-home-screen", null, { expires: 1 });
      this.deferredPrompt = null;
    },
    async install() {
      this.deferredPrompt.prompt();
    },
  },
};
</script>