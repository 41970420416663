<template>
  <div class="row justify-content-between py-4">
    <img src="../assets/icons/geetha-logo.png" class="logo-geetha col-5" />
    <div
      class="actions d-flex flex-column justify-content-evenly align-items-center col-2"
    >
      <a href="#map">
        <img src="../assets/icons/map-icon.png" />
      </a>
      <a
        id="announcement-icon"
        :class="{ 'new-announcement': announcement }"
        href="#announcements"
        @click="removeIndicator()"
      >
        <img src="../assets/icons/announcement-icon.png" />
      </a>
    </div>
    <img src="../assets/icons/clims-logo.svg" class="logo-icc col-5" />
  </div>
</template>
<script>
export default {
  props: ["newAnnouncement"],
  data() {
    return {
      announcement: false,
    };
  },
  updated() {
    console.log(this.newAnnouncement);
    this.announcement = this.newAnnouncement;
  },
  methods: {
    removeIndicator() {
      document
        .getElementById("announcement-icon")
        .classList.remove("new-announcement");
    },
  },
};
</script>