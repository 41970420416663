<template>
  <div class="regulation">
    <ol class="regulation-wrapper-list">
      <li>
        <div class="info-title">Safety and Security</div>
        <ol type="a">
          <li>
            <strong> Emergency Procedures: </strong>

            Familiarize yourself with emergency exits and procedures outlined by
            camp staff. In any case contact your responsible GL of your team.
          </li>

          <li>
            <strong> Health and First Aid: </strong>

            A designated first aid station is available on site. Campers must
            inform staff of any medical conditions or medications upon arrival.
          </li>
          <li>
            <strong> Camp Supervision: </strong>

            Campers are required to stay within designated areas and follow
            instructions from camp counselors and staff.
          </li>
        </ol>
      </li>
      <li>
        <div class="info-title">Conduct and Behavior</div>
        <ol type="a">
          <li>
            <strong> Respect: </strong>

            Treat all campers, staff, and facilities with respect. Bullying,
            harassment, or disruptive behavior will not be tolerated.
            Vandalizing or damage camp property and equipment is a serious
            offense and ill not be tolerated. This includes any damage to
            cabins, bunks, window screens, doors washrooms, dining hall, and any
            other building signs, program equipment and including the natural
            environment. You will be held responsible for the cost of repair or
            replacement, including labor costs.
          </li>
          <li>
            <strong> Quiet Hours: </strong>

            Respect silent slot from 12:00 ΑM to 7:00 AM to ensure a peaceful
            environment for all campers. Going out after the silent time is
            forbidden.
          </li>
          <li>
            <strong> Alcohol and Drugs: </strong>

            Possession or use of alcohol and illegal substances is strictly
            prohibited.
          </li>
          <li>
            <strong> Smoking area: </strong>

            Smoking is forbidden everywhere except a specific area, which is
            located at the outdoor area of the entertainment room.
          </li>
        </ol>
      </li>
      <li>
        <div class="info-title">Environmental Responsibility</div>
        <ol type="a">
          <li>
            <strong> Waste Management: </strong>

            Use designated bins for recycling and waste disposal. Always keep
            the campsite clean and tidy.
          </li>

          <li>
            <strong> Fire Safety: </strong>
            Fires are strictly prohibited throughout the campsite. No open
            flames or campfires are allowed at any time.
          </li>
        </ol>
      </li>
      <li>
        <div class="info-title">Program Participation</div>
        <ol type="a">
          <li>
            <strong> Activity Safety: </strong>
            Participate in all activities under the guidance and supervision of
            trained staff. Follow safety guidelines for each activity.
          </li>
          <li>
            <strong> Personal Belongings: </strong>

            Camp is not responsible for lost or damaged personal belongings.
            Keep valuables always secure. Group Leaders and Deputy Director can
            help you.
          </li>
          <li>
            <strong>Participants: </strong>

            Participants and GLs are not allowed to leave the camp.
          </li>
        </ol>
      </li>
      <li>
        <div class="info-title">Legal Compliance</div>
        <ol type="a">
          <li>
            <strong> Local Laws: </strong>

            Abide by all local laws and regulations of Greece and the city of
            Athens during your stay at ICC 2024.
          </li>
          <li>
            <strong> Camp Rules Compliance: </strong>

            Failure to comply with camp rules may result in disciplinary action
            or dismissal from the program.
          </li>
        </ol>
      </li>
    </ol>
    <div class="asterisk">*</div>
    Please be aware that accommodation and some activities are held in a
    military facility, therefore during the camp, staff is allowed to perform
    UNFORSEEN CHECKS, to make sure that the cardinal rules are respected.
    <br /><br />

    <div class="asterisk">*</div>
    Accept the authority of the Camp Director and staff members.
  </div>
</template>

<script>
export default {};
</script>

<style>
ol {
  padding-left: 1rem !important;
}
ol > li::marker {
  font-weight: bold;
}
</style>