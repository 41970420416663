<template>
  <div class="regulation">
    <div class="regulation-wrapper-list">
      <div class="">
        <div class="info-title">Pool schedule</div>
        <div class="activity row mt-2 align-items-center">
          <div class="col-3 activity-description">30/07</div>
          <div class="col-5 px-0 activity-time">17.00 - 21.00</div>
        </div>
        <div class="activity row mt-2 align-items-center">
          <div class="col-3 activity-description">31/07</div>
          <div class="col-5 px-0 activity-time">17.00 - 21.00</div>
        </div>
        <div class="activity row mt-2 align-items-center">
          <div class="col-3 activity-description">01/08</div>
          <div class="col-5 px-0 activity-time">17.00 - 21.00</div>
        </div>
        <div class="activity row mt-2 align-items-center">
          <div class="col-3 activity-description">02/08</div>
          <div class="col-5 px-0 activity-time">-</div>
        </div>
        <div class="activity row mt-2 align-items-center">
          <div class="col-3 activity-description">03/08</div>
          <div class="col-5 px-0 activity-time">16.00 - 21.00</div>
        </div>
        <div class="activity row mt-2 align-items-center">
          <div class="col-3 activity-description">04/08</div>
          <div class="col-5 px-0 activity-time">12.00 - 21.00</div>
        </div>
        <div class="activity row mt-2 align-items-center">
          <div class="col-3 activity-description">05/08</div>
          <div class="col-5 px-0 activity-time">17.00 - 21.00</div>
        </div>
        <div class="activity row mt-2 align-items-center">
          <div class="col-3 activity-description">06/08</div>
          <div class="col-5 px-0 activity-time">17.00 - 21.00</div>
        </div>
        <div class="activity row mt-2 align-items-center">
          <div class="col-3 activity-description">07/08</div>
          <div class="col-5 px-0 activity-time">17.00 - 21.00</div>
        </div>
        <div class="activity row mt-2 align-items-center">
          <div class="col-3 activity-description">08/08</div>
          <div class="col-5 px-0 activity-time">17.00 - 21.00</div>
        </div>
        <div class="activity row mt-2 align-items-center">
          <div class="col-3 activity-description">09/08</div>
          <div class="col-5 px-0 activity-time">17.00 - 21.00</div>
        </div>
        <div class="activity row mt-2 align-items-center">
          <div class="col-3 activity-description">10/08</div>
          <div class="col-5 px-0 activity-time">08.00 - 21.00</div>
        </div>
      </div>
      <div class="mt-3">
        <div class="info-title">Canteen schedule</div>
        <div class="activity row mt-2 align-items-center">
          <div class="col-3 activity-description">30/07</div>
          <div class="col-5 px-0 activity-time">06.00 - 09.00</div>
        </div>
        <div class="activity row mt-2 align-items-center">
          <div class="col-3 activity-description">31/07</div>
          <div class="col-5 px-0 activity-time">06.00 - 09.00</div>
        </div>
        <div class="activity row mt-2 align-items-center">
          <div class="col-3 activity-description">01/08</div>
          <div class="col-5 px-0 activity-time">06.00 - 09.00</div>
        </div>
        <div class="activity row mt-2 align-items-center">
          <div class="col-3 activity-description">02/08</div>
          <div class="col-5 px-0 activity-time">-</div>
        </div>
        <div class="activity row mt-2 align-items-center">
          <div class="col-3 activity-description">03/08</div>
          <div class="col-5 px-0 activity-time">06.00 - 14.00</div>
        </div>
        <div class="activity row mt-2 align-items-center">
          <div class="col-3 activity-description">04/08</div>
          <div class="col-5 px-0 activity-time">06.00 - 14.00</div>
        </div>
        <div class="activity row mt-2 align-items-center">
          <div class="col-3 activity-description">05/08</div>
          <div class="col-5 px-0 activity-time">06.00 - 09.00</div>
        </div>
        <div class="activity row mt-2 align-items-center">
          <div class="col-3 activity-description">06/08</div>
          <div class="col-5 px-0 activity-time">06.00 - 09.00</div>
        </div>
        <div class="activity row mt-2 align-items-center">
          <div class="col-3 activity-description">07/08</div>
          <div class="col-5 px-0 activity-time">06.00 - 09.00</div>
        </div>
        <div class="activity row mt-2 align-items-center">
          <div class="col-3 activity-description">08/08</div>
          <div class="col-5 px-0 activity-time">06.00 - 09.00</div>
        </div>
        <div class="activity row mt-2 align-items-center">
          <div class="col-3 activity-description">09/08</div>
          <div class="col-5 px-0 activity-time">06.00 - 09.00</div>
        </div>
        <div class="activity row mt-2 align-items-center">
          <div class="col-3 activity-description">10/08</div>
          <div class="col-5 px-0 activity-time">06.00 - 22.30</div>
        </div>
        <div class="activity row mt-2 align-items-center">
          <div class="col-3 activity-description">11/08</div>
          <div class="col-5 px-0 activity-time">06.00 - 22.30</div>
        </div>
      </div>
      <div class="mt-3">
        <div class="info-title">Laundry schedule</div>
        <div class="activity row mt-2">
          <div class="col-3 activity-description">31/07</div>
          <div class="col-8 activity-time laundry">
            <div class="row type">
              <img
                class="img px-0 mr-3"
                src="../assets/icons/clothes.svg"
              />Colourful clothes
            </div>
            <div class="row building">
              12th building & 7th building (1st & 3rd floor)
            </div>
          </div>
        </div>
        <div class="activity row mt-2">
          <div class="col-3 activity-description">01/08</div>
          <div class="col-8 activity-time laundry">
            <div class="row type">
              <img
                class="img px-0 mr-3"
                src="../assets/icons/clothes.svg"
              />Colourful clothes
            </div>
            <div class="row building">
              3rd building & 7th building (2nd floor)
            </div>
          </div>
        </div>
        <div class="activity row mt-2">
          <div class="col-3 activity-description">02/08</div>
          <div class="col-8 activity-time laundry">
            <div class="row type">
              <img
                class="img px-0 mr-3"
                src="../assets/icons/towel.svg"
              />Bedsheets & towels
            </div>
            <div class="row building">
              12th building & 7th building (1st & 3rd floor)
            </div>
          </div>
        </div>
        <div class="activity row mt-2">
          <div class="col-3 activity-description">03/08</div>
          <div class="col-8 activity-time">-</div>
        </div>
        <div class="activity row mt-2">
          <div class="col-3 activity-description">04/08</div>
          <div class="col-8 activity-time">-</div>
        </div>
        <div class="activity row mt-2">
          <div class="col-3 activity-description">05/08</div>
          <div class="col-8 activity-time laundry">
            <div class="row type">
              <img
                class="img px-0 mr-3"
                src="../assets/icons/towel.svg"
              />Bedsheets & towels
            </div>
            <div class="row building">
              3rd building & 7th building (2nd floor)
            </div>
          </div>
        </div>
        <div class="activity row mt-2">
          <div class="col-3 activity-description">06/08</div>
          <div class="col-8 activity-time laundry">
            <div class="row type">
              <img
                class="img px-0 mr-3"
                src="../assets/icons/clothes.svg"
              />Colourful clothes
            </div>
            <div class="row building">
              12th building & 7th building (1st & 3rd floor)
            </div>
          </div>
        </div>
        <div class="activity row mt-2">
          <div class="col-3 activity-description">07/08</div>
          <div class="col-8 activity-time laundry">
            <div class="row type">
              <img
                class="img px-0 mr-3"
                src="../assets/icons/clothes.svg"
              />Colourful clothes
            </div>
            <div class="row building">
              3rd building & 7th building (2nd floor)
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.img {
  width: 14px !important;
  aspect-ratio: 1;
  margin-right: 7px;
}
.activity {
  min-height: 30px;
  .activity-time {
    font-size: 14px;
    font-weight: 700;
    opacity: 0.85;
  }
  .activity-description {
    padding-left: 1rem;
    padding-right: 0;
  }
}
</style>