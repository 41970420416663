<template>
  <div v-if="menuByDate.length" class="activities">
    <div
      class="menu-item row"
      v-for="menuItem of menuByDate"
      :key="menuItem.date"
    >
      <div class="col-3 menu-item-time">
        {{ menuItem.type }}
      </div>
      <div class="col-8 pl-1 menu-item-description">
        <div class="meal row align-items-start">
          <img v-if="(isDinner(menuItem) || isLunch(menuItem)) && menuItem.meal !== '-'" src="../assets/icons/meal.svg" class="col-1 px-0 mr-3" width="25" />
					<div v-else class="col-1 px-0 mr-3"></div>
          <div class="col-11">
						{{ separateMeals(menuItem.meal) }}
					</div>
        </div>
        <div class="mt-1 row" v-if="menuItem.base">
					<div class="col-1 px-0 mr-3"></div>
					<div class="col-11">
						{{ menuItem.base }}
					</div>
				</div>
        <div class="mt-2 row" v-if="menuItem.salad">
          <img
            src="../assets/icons/salad.svg"
            class="col-1 px-0 mr-3"
            width="25"
          />
					<div class="col-11">
						{{ menuItem.salad }}
					</div>
        </div>
        <div class="mt-2 row" v-if="menuItem.dessert">
          <img
            src="../assets/icons/dessert.svg"
            class="col-1 px-0 mr-3"
            width="25"
          />
					<div class="col-11">
						{{ menuItem.dessert }}
					</div>
        </div>
      </div>
      <hr v-if="!isDinner(menuItem)" />
    </div>
  </div>
</template>
<script>
import menuData from "../../api/menu.json";
export default {
  components: {},
  props: {
    date: Date,
  },
  name: "Menu",
  data() {
    return {
      menu: menuData,
    };
  },
  updated() {},
  computed: {
    menuByDate() {
      return this.menu.filter(
        (item) =>
          new Date(item.dateTime).getDate() === this.date.getDate() &&
          new Date(item.dateTime).getMonth() === this.date.getMonth()
      );
    },
  },
  methods: {
    isLunch(item) {
      return item.type === "Lunch";
    },
    isDinner(item) {
      return item.type === "Dinner";
    },
    separateMeals(desc) {
      return desc.split(", ").join("\n");
    },
  },
};
</script>

<style scoped lang="scss">
.menu-item {
  gap: 1rem;
  .menu-item-time {
    font-size: 16px;
    font-weight: 700;
    opacity: 0.85;
  }
  .menu-item-description {
    font-size: 16px;
    white-space: pre-line;
    padding-left: 1rem;
  }
  .meal {
    img {
      position: relative;
      top: 6px;
    }
  }
}
</style>