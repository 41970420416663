<template>
  <div v-if="teamActivities.length" class="activities">
    <div
      class="activity row mt-2 align-items-center"
      v-for="activity of teamActivities"
      :key="activity.date"
    >
      <div class="col-2 activity-time">
        {{ getTimeFromDatetime(activity.dateTime) }}
      </div>
      <div class="col-9 pl-1 activity-description">
        {{ activity.description }}
      </div>
    </div>
  </div>
  <div v-else-if="!team.length">
    <strong>Please select your team to see your activities!</strong>
  </div>
  <div v-else>
    <strong>You have no activities for this day!</strong>
  </div>
</template>

<script>
import activitiesData from "../../api/activities.json";
export default {
  components: {},
  props: {
    team: String,
    date: Date,
  },
  name: "Activities",
  data() {
    return {
      activities: activitiesData,
    };
  },
  updated() {
  },
  computed: {
    teamActivities() {
      return this.activities
        .filter((activity) => activity.team === this.team)
        .filter(
          (activity) =>
            new Date(activity.dateTime).getDate() === this.date.getDate() &&
            new Date(activity.dateTime).getMonth() === this.date.getMonth()
        );
    },
  },
  methods: {
    getTimeFromDatetime(date) {
      let hours = new Date(date).getHours();
      let minutes = new Date(date).getMinutes();
      if (hours < 10) hours = "0" + hours;
      if (minutes < 10) minutes = "0" + minutes;
      return `${hours}:${minutes}`;
    },
  },
};
</script>

<style scoped lang="scss">
.activity {
  min-height: 30px;
  gap: 0.5rem;
  .activity-time {
    font-size: 18px;
    font-weight: 700;
    opacity: 0.85;
  }
  .activity-description {
    padding-left: 1rem;
    padding-right: 0;
  }
}
</style>
