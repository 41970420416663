<template>
	<div class="activities">
		<div class="menu-item row" v-for="team of groupLeaders" :key="team.team">
			<div class="col-3 menu-item-time" :style="`color: ${team.color} `">
				{{ team.team }}
			</div>
			<div class="col-8 pl-1 menu-item-description mb-3">
				<div
					class="row align-items-center mb-1 group-leader-row"
					v-for="gl of team.groupLeaders"
					:key="gl.phone"
				>
					<div class="col-8">
						<span>
							{{ gl.fullName }}
						</span>
					</div>
					<div class="col-2">
						<a :href="`tel:${gl.phone}`">
							<img
								src="../assets/icons/telephone-receiver-material-svgrepo-com.svg"
                width="20"
							/>
						</a>
					</div>
					<div class="col-1" @click="copyPhone(gl.phone)">
							<img
								src="../assets/icons/copy-svgrepo-com.svg"
                width="20"
							/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import groupLeadersData from "../../api/groupLeaders.json";
export default {
	components: {},
	props: {
		team: String,
		date: Date,
	},
	name: "Contact",
	data() {
		return {
			groupLeaders: groupLeadersData,
		};
	},
	updated() {},
	computed: {},
	methods: {
    copyPhone(phone) { 

      // Copy the text inside the text field
      navigator.clipboard.writeText(phone);
      // Alert the copied text
      alert("Copied phone to clipboard");
    }
  },
};
</script>

<style scoped lang="scss">
.gl {
	min-height: 30px;
	gap: 0.5rem;
	.gl-time {
		font-size: 18px;
		font-weight: 700;
		opacity: 0.85;
	}
	.gl-description {
		padding-left: 1rem;
	}
}
.group-leader-row {
  font-size: 15px;
}
</style>
