import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import { setupCalendar } from "v-calendar";

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import "./assets/styles/main.css";

const app = createApp(App);
app.mount("#app");
app.use(setupCalendar, {});
