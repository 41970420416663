<template>
  <div class="row my-5" id="map">
    <h5 class="px-0 pt-2">Camp map</h5>
    <img src="../assets/icons/map.png" class="px-0 map" />
    <div class="memo">
      <p>1. Swimming Pool</p>
      <p>2. Football Field</p>
      <p>3. Basketball Court</p>
      <p>4. 3rd Company</p>
      <p>5. 7th Company</p>
      <p>6. 12th Company</p>
      <p>7. Restaurants (Ground Floor) - Entertainment Room (1st Floor)</p>
      <p>8. 11th Company</p>
      <p>9. Medical center</p>
      <p>SOS. Emergency point</p>
      <p class="restricted-area">* Restricted areas</p>
    </div>
  </div>
</template>