<template>
  <div
    v-if="announcements.length"
    class="announcements row py-3"
    id="announcements"
  >
    <div class="title mb-3 px-0">Announcements</div>
    <div
      class="announcement mt-3"
      v-for="announcement of announcements"
      :key="announcement.date"
    >
      <div class="text row align-items-center">
        <img
          src="../assets/icons/announcement-megaphone.svg"
          class="col-1 px-0"
          width="25"
        />
        <p class="col-11 mb-0" v-html="announcement.description"></p>
      </div>
      <span class="date mt-1">{{ timeConverter(announcement.date) }}</span>
    </div>
  </div>
  <div v-else id="announcements" class="announcements row py-3">
    <div class="title mb-3 px-0">Announcements</div>
    <div class="announcement">There are no announcements at this time.</div>
  </div>
</template>

<script>
import { initializeApp } from "firebase/app";
import {
  getDocs,
  collection,
  getFirestore,
  query,
  orderBy,
} from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyD3dayXwdZ63hCfR9qN22anY3jwsft9bx0",
  authDomain: "icc-2024.firebaseapp.com",
  projectId: "icc-2024",
  storageBucket: "icc-2024.appspot.com",
  appId: "1:731549078672:web:6317f03e8e5d6fe3d27415",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export default {
  name: "Announcements",
  emits: ["newAnnouncementPresent"],
  data() {
    return {
      announcements: [],
    };
  },
  async created() {
    const querySnapshot = await getDocs(
      query(collection(db, "announcements"), orderBy("date", "desc"))
    );
    querySnapshot.forEach((doc) => {
      this.announcements.push(doc.data());
    });
    if (!this.announcements.length) return;
    const today = new Date();
    const firstAnnouncementTime = new Date(
      this.announcements[0].date.seconds * 1000
    );
    if ((today - firstAnnouncementTime) / 1000 / 60 / 60 < 12) {
      this.$emit("newAnnouncementPresent");
    }
  },
  methods: {
    timeConverter(UNIX_timestamp) {
      var a = new Date(UNIX_timestamp.seconds * 1000);
      var months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      var month = months[a.getMonth()];
      var date = a.getDate();
      if (date == 1 || date == 21 || date === 31) date = date + "st";
      else if (date == 2 || date == 22) date = date + "nd";
      else if (date == 3 || date == 23) date = date + "rd";
      else date = date + "th";
      var hour = a.getHours();
      if (hour < 10) hour = "0" + hour;
      var min = a.getMinutes();
      if (min < 10) min = "0" + min;
      var time = month + " " + date + ", " + hour + ":" + min;
      return time;
    },
  },
};
</script>

<style>
</style>