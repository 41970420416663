<template>
  <!-- <Banner /> -->
  <div class="w-100 px-4 pb-4 container-fluid wrap-container">
    <HeaderSection :new-announcement="newAnnouncement" />
    <div class="row">
      <DatePicker
        class="col-12"
        mode="date"
        view="weekly"
        :is-required="true"
        v-model="date"
        :first-day-of-week="2"
      />
    </div>

    <div v-if="date >= new Date(2024, 6, 29) && date < new Date(2024, 7, 12)">
      <div class="row mt-3">
        <div class="dropdown px-0">
          <button
            class="btn btn-primary dropdown-toggle"
            type="button"
            id="dropdownMenuButton"
            data-bs-toggle="dropdown"
            data-bs-auto-close="outside"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Select your team
          </button>
          <div
            class="dropdown-menu"
            id="team-dropdown-menu"
            aria-labelledby="dropdownMenuButton"
          >
            <a
              class="dropdown-item"
              v-for="team of teams"
              :key="team"
              @click="teamChanged(team)"
              data-bs-dismiss="dropdown"
            >
              <img class="team-icon" width="22" :src="getTeamImagePath(team)" />
              {{ team }}
              <img
                v-if="selectedTeam === team"
                class="selected"
                width="22"
                src="../assets/icons/check-icon.png"
              />
            </a>
          </div>
        </div>
      </div>
      <!-- </div> -->
      <div class="row">
        <div class="accordion mt-3 px-0" id="accordion">
          <div class="accordion-item">
            <h2 class="accordion-header" id="activities">
              <button
                class="accordion-button collapsed"
                id="activities-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse-activities"
                aria-expanded="false"
                aria-controls="collapse-activities"
              >
                Activities
              </button>
            </h2>
            <div
              id="collapse-activities"
              class="accordion-collapse collapse"
              aria-labelledby="activities"
              data-bs-parent="#accordion"
            >
              <div class="accordion-body">
                <Activities :team="selectedTeam" :date="date" />
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingMenu">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse-menu"
                aria-expanded="false"
                aria-controls="collapse-menu"
              >
                Menu
              </button>
            </h2>
            <div
              id="collapse-menu"
              class="accordion-collapse collapse"
              aria-labelledby="headingMenu"
              data-bs-parent="#accordion"
            >
              <div class="accordion-body px-3">
                <Menu :date="date" />
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="contact">
              <button
                class="accordion-button collapsed"
                id="contact-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse-contact"
                aria-expanded="false"
                aria-controls="collapse-contact"
              >
                Contact
              </button>
            </h2>
            <div
              id="collapse-contact"
              class="accordion-collapse collapse"
              aria-labelledby="contact"
              data-bs-parent="#accordion"
            >
              <div class="accordion-body">
                <Contact :team="selectedTeam" />
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="useful-info">
              <button
                class="accordion-button collapsed"
                id="useful-info-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse-useful-info"
                aria-expanded="false"
                aria-controls="collapse-useful-info"
              >
                Useful Info
              </button>
            </h2>
            <div
              id="collapse-useful-info"
              class="accordion-collapse collapse"
              aria-labelledby="useful-info"
              data-bs-parent="#accordion"
            >
              <div class="accordion-body">
                <UsefulInfo />
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingRegulation">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse-regulation"
                aria-expanded="false"
                aria-controls="collapse-regulation"
              >
                Regulation
              </button>
            </h2>
            <div
              id="collapse-regulation"
              class="accordion-collapse collapse"
              aria-labelledby="headingRegulation"
              data-bs-parent="#accordion"
            >
              <div class="accordion-body">
                <Regulation />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="row mt-5 false-date">
      <h5>
        ICC 2024 lasts from July 29th to August 11th. Select a date between this
        range.
      </h5>
    </div>
    <Map />
    <Announcements
      v-if="!offlineStatus"
      @new-announcement-present="newAnnouncement = true"
    />
    <div v-else id="announcements" class="announcements row py-3">
      <div class="title mb-3 px-0">Announcements</div>
      <div class="announcement">
        {{ offlineStatus }}
      </div>
    </div>
  </div>
</template>

<script>
import { Calendar, DatePicker } from "v-calendar";
import "v-calendar/style.css";

import Activities from "./Activities.vue";
import Map from "./Map.vue";
import HeaderSection from "./HeaderSection.vue";
import Announcements from "./Announcements.vue";
import Banner from "./Banner.vue";
import Menu from "./Menu.vue";
import Contact from "./Contact.vue";
import UsefulInfo from "./UsefulInfo.vue";
import Regulation from "./Regulation.vue";

export default {
  components: {
    Calendar,
    DatePicker,
    Activities,
    Map,
    HeaderSection,
    Banner,
    Announcements,
    Menu,
    Contact,
    UsefulInfo,
    Regulation,
  },
  name: "HomePage",
  data() {
    return {
      date: new Date(),
      selectedTeam: "",
      teams: [
        "Aphrodite",
        "Ares",
        "Artemis",
        "Athena",
        "Poseidon",
        "Zeus",
        "Visitor",
      ],
      offlineStatus: `Your network status is Offline. You need to be online to check the announcements.`,
      newAnnouncement: false,
    };
  },
  mounted() {
    if (navigator.onLine) {
      this.offlineStatus = "";
    }
  },
  methods: {
    getTeamImagePath(team) {
      let images = require.context("../assets/icons/", false, /\.svg$/);
      return images("./" + team.toLowerCase() + ".svg");
    },
    async teamChanged(team) {
      this.selectedTeam = team;

      await setTimeout(() => {
        document.getElementById("team-dropdown-menu").classList.remove("show");
      }, 200);

      await setTimeout(() => {
        document
          .getElementById("activities-button")
          .classList.remove("collapsed");
        document
          .getElementById("collapse-activities")
          .classList.remove("collapse");
        document
          .getElementById("collapse-activities")
          .classList.add("collapsing");
        document
          .getElementById("collapse-activities")
          .classList.remove("collapsing");
        document
          .getElementById("collapse-activities")
          .classList.add("show", "collapse");
      }, 300);
    },
  },
};
</script>
